.custombutton {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 2.25rem;
  background: linear-gradient(
    0deg,
    rgba(0, 172, 238, 1) 0%,
    rgba(2, 126, 251, 1) 100%
  ) !important;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: 500;
  border: 2px solid var(--bs-primary);
  outline: none;
  overflow: hidden;
  font-size: 20px;
  cursor: pointer;
  margin: 0 auto;
}

.arrowicon {
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}

.custombutton:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.custombutton:hover .arrowicon {
  transform: translate(4px);
}

.custombutton:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.custombutton::before {
  content: '';
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

@media (max-width: 768px) {
  .custombutton {
    padding-block: 0.4rem;
    padding-inline: 1.5rem;
    font-size: 18px;
  }

  .arrowicon {
    width: 20px;
    height: 20px;
  }
}

@media (max-width: 480px) {
  .custombutton {
    padding-block: 0.3rem;
    padding-inline: 1.25rem;
    font-size: 16px;
    gap: 5px;
  }

  .arrowicon {
    width: 16px;
    height: 16px;
  }
}
