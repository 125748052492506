@media (max-width: 1199px) {
    .recent-post-screen {
      flex-direction: column; 
    }
  }
  .recent-post-screen {
    display: flex;
    gap: 16px; 
    border-bottom: 1px solid #ddd; 
    padding-bottom: 16px;
  }
  .recent-post-screen:last-child {
    border-bottom: none;
  }
  
  .recent-blog-title {
    font-size: 17px;
   cursor: pointer;
  }