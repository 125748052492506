.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent !important;
  border-radius: 0.25rem;
}

.bg-breadcrumb {
  position: relative;
  overflow: hidden;
  background: rgba(221, 239, 255, 1) !important;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 150px 0 30px 0;
  transition: 0.5s;
}

.bg-breadcrumb::after {
  content: '';
  position: absolute;
  bottom: -20%;
  left: -10%;
  width: 600px;
  height: 600px;
  border-radius: 300px;
  border: 50px solid rgb(108 212 239 / 51%);
  background: transparent;
  animation: RotateMoveSingle 5s linear infinite;
  z-index: 0;
}

.bg-breadcrumb .breadcrumb {
  position: relative;
  z-index: 2;
}

.breadcrumb-item{
  cursor: pointer;
}

@keyframes RotateMoveSingle {
  0% {
    -webkit-transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0)
      rotateZ(0deg);
    transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
  }

  100% {
    -webkit-transform: rotateZ(360deg) rotate(360deg) translate3d(0, 1%, 0)
      rotateZ(-360deg);
    transform: rotateZ(360deg) rotate(360deg) translate3d(0, 1%, 0)
      rotateZ(-360deg);
  }

  0% {
    bottom: 0px;
  }

  50% {
    left: -10px;
  }

  75% {
    bottom: 10%;
  }

  100% {
    bottom: 0px;
  }
}

.bg-breadcrumb::before {
  content: '';
  position: absolute;
  top: -15%;
  right: -10%;
  width: 600px;
  height: 600px;
  border-radius: 300px;
  border: 50px solid rgb(108 212 239 / 51%);
  background: transparent;
  animation: RotateMoveSingle 5s linear infinite;
  z-index: 0;
}

@keyframes RotateMoveSingle {
  0% {
    -webkit-transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0)
      rotateZ(0deg);
    transform: rotateZ(0deg) rotate(0deg) translate3d(0, 1%, 0) rotateZ(0deg);
  }

  100% {
    -webkit-transform: rotateZ(360deg) rotate(360deg) translate3d(0, 1%, 0)
      rotateZ(-360deg);
    transform: rotateZ(360deg) rotate(360deg) translate3d(0, 1%, 0)
      rotateZ(-360deg);
  }

  0% {
    top: 0px;
  }

  50% {
    top: 10%;
  }

  100% {
    top: 0px;
  }
}

@media (max-width: 992px) {
  .bg-breadcrumb {
    padding-top: 100px !important;
  }
}

.bg-breadcrumb .breadcrumb-item a {
  color: var(--bs-dark) !important;
}

.breadcrumb-animation {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.breadcrumb-animation li {
  position: absolute;
  display: block;
  list-style: none;
  width: 20px;
  height: 20px;
  background: rgba(168, 229, 250, 0.85);
  animation: animate 25s linear infinite;
  bottom: -150px;
}

.breadcrumb-animation li:nth-child(1) {
  left: 25%;
  width: 80px;
  height: 80px;
  animation-delay: 0s;
}

.breadcrumb-animation li:nth-child(2) {
  left: 10%;
  width: 20px;
  height: 20px;
  animation-delay: 2s;
  animation-duration: 12s;
}

.breadcrumb-animation li:nth-child(3) {
  left: 70%;
  width: 20px;
  height: 20px;
  animation-delay: 4s;
}

.breadcrumb-animation li:nth-child(4) {
  left: 40%;
  width: 60px;
  height: 60px;
  animation-delay: 0s;
  animation-duration: 18s;
}

.breadcrumb-animation li:nth-child(5) {
  left: 65%;
  width: 20px;
  height: 20px;
  animation-delay: 0s;
}

.breadcrumb-animation li:nth-child(6) {
  left: 75%;
  width: 110px;
  height: 110px;
  animation-delay: 3s;
}

.breadcrumb-animation li:nth-child(7) {
  left: 35%;
  width: 150px;
  height: 150px;
  animation-delay: 7s;
}

.breadcrumb-animation li:nth-child(8) {
  left: 50%;
  width: 25px;
  height: 25px;
  animation-delay: 15s;
  animation-duration: 45s;
}

.breadcrumb-animation li:nth-child(9) {
  left: 20%;
  width: 15px;
  height: 15px;
  animation-delay: 2s;
  animation-duration: 35s;
}

.breadcrumb-animation li:nth-child(10) {
  left: 85%;
  width: 150px;
  height: 150px;
  animation-delay: 0s;
  animation-duration: 11s;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
