.about-area {
  position: relative;
  z-index: 1;
  padding-top: 50px;
  padding-bottom: 30px;
}

.about-image {
  text-align: center;
}

.feature-images {
  object-fit: contain;
}

.about-content {
  padding-left: 30px;
  padding-right: 30px;
}

.about-content .sub-title {
  display: block;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 17px;
  font-weight: bold;
  color: #3e85c6;
}

.about-content h2 {
  margin-bottom: 10px;
  font-size: 40px;
}

.about-content p {
  margin-bottom: 20px;
}

.about-content .features-list {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style-type: none;
  margin: 10px -10px 0;
}

.about-content .features-list li {
  flex: 0 0 50%;
  max-width: 50%;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.about-content .features-list li span {
  display: block;
  background-color: #f8faff;
  border-radius: 5px;
  padding: 13px 12px 8px 40px;
  z-index: 1;
  position: relative;
  transition: 0.5s;
  font-weight: 600;
  font-size: 15px;
}

.about-content .features-list li span:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  border-radius: 5px;
  background-image: linear-gradient(to right, #3E85C6, #074783);
  z-index: -1;
  transition: 0.5s;
}

.about-content .features-list li span i {
  font-size: 12px;
  color: #fff;
  background-color: rgb(0, 195, 255);
  width: 23px;
  height: 23px;
  line-height: 23px;
  transition: 0.5s;
  text-align: center;
  border-radius: 3px;
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.about-content .features-list li span:hover {
  color: #fff;
}

.about-content .features-list li span:hover:before {
  width: 100%;
}

.about-content .features-list li span:hover i {
  background-color: #01476d;
}

@media (max-width: 1400px) {
  .about-content h2 {
    margin-bottom: 10px;
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .about-content h2 {
    font-size: 25px;
  }

  .about-content .sub-title {
    font-size: 13px;
  }
}

@media (max-width: 768px) {
  .about-area {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .about-content {
    padding-left: 15px;
    padding-right: 15px;
  }

  .about-content h2 {
    font-size: 23px;
  }

  .about-content .features-list li {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .about-content p {
    font-size: 14px;
  }

  .feature-image {
    height: auto;
    width: 80%;
  }
}

@media (max-width: 480px) {
  .about-content p {
    font-size: 13px;
  }

  .about-content .features-list li span {
    padding: 10px 10px 5px 35px;
    font-size: 13px;
  }

  .feature-image {
    width: 100%;
  }
}
.feature-title .highlight {
  color: #3e85c6;
}

.feature-image img {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.feature-image img.loaded {
  opacity: 1;
}
.hidden {
  display: none;
}
