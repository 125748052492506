.header::after {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  width: 60%;
  height: 100%;
  background-color: rgba(205, 217, 206, 0.456);
  border-radius: 22% 78% 33% 67% / 32% 0% 100% 68%;
  animation: bgMove 5s linear infinite;
  z-index: -1;
}

@keyframes bgMove {
  0% {
    right: 0px;
  }
  50% {
    right: 20px;
  }
  100% {
    right: 0px;
  }
}

.header::before {
  content: '';
  position: absolute;
  bottom: -9%;
  left: -7%;
  width: 400px;
  height: 400px;
  border-radius: 200px;
  border: 30px solid rgba(46, 190, 226, 0.511);
  background: transparent;
  animation: RotateMoveHeader 5s linear infinite;
  z-index: -1;
}

@keyframes RotateMoveHeader {
  0% {
    transform: rotateZ(0deg) rotate(0deg) translate3d(0, 10%, 0) rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg) rotate(360deg) translate3d(0, 10%, 0)
      rotateZ(-360deg);
  }
}

.hero-header {
  padding-top: 85px;
  padding-bottom: 100px;
  position: relative;
}

.hero-header::after {
  content: '';
  position: absolute;
  left: 100px;
  bottom: 100px;
  width: 58px;
  height: 50px;
  background: url(../../assets/img/sty-1.png) center center no-repeat;
  animation: RotateMoveSty-3 45s linear infinite;
  transition: 0.5s;
  z-index: -1;
}

@keyframes RotateMoveSty-3 {
  0% {
    left: 100px;
  }
  40% {
    bottom: -0px;
  }
  50% {
    left: 700px;
  }
  70% {
    bottom: 500px;
  }
  80% {
    left: 400px;
  }
  95% {
    bottom: -0px;
  }
  100% {
    left: 100px;
  }
}

.hero-header::before {
  content: '';
  position: absolute;
  left: 100px;
  bottom: 100px;
  width: 300px;
  height: 300px;
  border-radius: 150px;
  border: 30px solid rgba(128, 198, 236, 0.388);
  background: transparent;
  animation: RotateMoveSty-4 45s linear infinite;
  transition: 0.5s;
  z-index: -1;
}

@keyframes RotateMoveSty-4 {
  0% {
    left: 100px;
  }
  40% {
    bottom: -0px;
  }
  50% {
    left: 700px;
  }
  70% {
    bottom: 500px;
  }
  80% {
    left: 400px;
  }
  95% {
    bottom: -0px;
  }
  100% {
    left: 100px;
  }
}

.hero-header .rotate-img {
  position: absolute;
  top: 70px;
  left: 20px;
  z-index: -2;
}

.hero-header .rotate-img .rotate-sty-2 {
  position: absolute;
  top: 100px;
  left: 50px;
  width: 50px;
  height: 50px;
  border-radius: 30px;
  border: 10px solid rgba(168, 229, 250, 0.85);
  background: transparent;
  animation: RotateMoveSty-2 45s linear infinite;
  transition: 0.5s;
}

@keyframes RotateMoveSty-2 {
  0% {
    left: 0px;
  }
  40% {
    top: -30px;
  }
  50% {
    left: 500px;
  }
  70% {
    top: 200px;
  }
  80% {
    left: 100px;
  }
  95% {
    top: -30px;
  }
  100% {
    left: 0px;
  }
}

@media (max-width: 540px) {
  .hero-header {
    margin-top: 0px !important;
    padding: 30px !important;
    animation: none;
  }

  .header::after {
    width: 30%;
    height: 50%;
    border-radius: 22% 78% 33% 67% / 32% 0% 100% 68%;
  }

  .header::before {
    width: 200px;
    height: 200px;
    border-radius: 200px;
    border: 20px solid rgba(46, 190, 226, 0.279);
    animation: RotateMoveHeader 50s linear infinite;
  }

  .hero-header::after {
    left: 70px;
    bottom: 70px;
  }

  .hero-header::before {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 541px) {
  .hero-header .rotate-img .rotate-sty-2 {
    z-index: -1 !important;
  }
}
@media (min-width: 541px) and (max-width: 768px) {
  .hero-header {
    margin-top: 0px !important;
    animation-duration: 40s;
    padding-top: 30px !important;
  }

  .hero-header .rotate-img .rotate-sty-2 {
    z-index: -1 !important;
  }
  .header::after,
  .header::before,
  .hero-header::after,
  .hero-header::before,
  .hero-header .rotate-img .rotate-sty-2 {
    animation-duration: 10s;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .hero-header .rotate-img img {
    margin-top: 10px;
  }
  .hero-header .rotate-img .rotate-sty-2 {
    z-index: -1 !important;
  }
  .header::after,
  .header::before,
  .hero-header::after,
  .hero-header::before,
  .hero-header .rotate-img .rotate-sty-2 {
    animation-duration: 45s;
  }
}

@media (min-width: 1025px) and (max-width: 1440px) {
  .hero-header .rotate-img img {
    margin-top: 100px;
  }
  .hero-header .rotate-img .rotate-sty-2 {
    z-index: -1 !important;
  }
  .header::after,
  .header::before,
  .hero-header::after,
  .hero-header::before,
  .hero-header .rotate-img .rotate-sty-2 {
    animation-duration: 30s;
  }
}

@media (min-width: 1441px) {
  .hero-header .rotate-img img {
    margin-top: 120px;
  }

  .header::after,
  .header::before,
  .hero-header::after,
  .hero-header::before,
  .hero-header .rotate-img .rotate-sty-2 {
    animation-duration: 20s;
  }
}

.button {
  min-width: 270px;
  min-height: 60px;
  display: inline-flex;
  font-family: 'Nunito', sans-serif;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.5px;
  font-weight: 500;
  color: #e7e3e3;
  background: #00d0ff;
  background: linear-gradient(
    90deg,
    rgb(129, 205, 230) 0%,
    rgb(25, 185, 209) 100%
  );
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px rgba(79, 177, 209, 0);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
}

.button::before {
  content: '';
  border-radius: 1000px;
  min-width: calc(240px + 12px);
  min-height: calc(40px + 12px);
  box-shadow: 0 0 60px rgba(0, 221, 255, 0.44);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  -ms-transition: all 0.3s ease-in-out 0s;
  -o-transition: all 0.3s ease-in-out 0s;
}

.button:hover,
.button:focus {
  border: 4px solid #0894b4;
  color: #0894b4;
  transform: translateY(-6px);
  background: #fff;
}

.button:hover::before,
.button:focus::before {
  opacity: 1;
}

.button::after {
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #00d0ff;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.button:hover::after,
.button:focus::after {
  animation: none;
  display: none;
}

.animation-btn::after{
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 6px solid #00d0ff;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 270px;
    height: 270px;
    opacity: 0;
  }
}

@media (max-width: 425px) {
  .button {
    min-width: 175px;
    min-height: 40px;
    font-size: 16px;
  }
  .button::before {
    min-width: calc(160px + 12px);
    min-height: calc(35px + 12px);
  }
}

@media (min-width: 425px) and (max-width: 768px) {
  .button {
    min-width: 200px;
    min-height: 44px;
    font-size: 18px;
  }
  .button::before {
    min-width: calc(185px + 12px);
  }
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
}

.fade-in-out {
  animation: fadeInOut 5s infinite;
}

.heding {
  background: linear-gradient(115deg, rgb(4, 124, 175), rgb(168, 228, 234));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-bg {
  background-image: linear-gradient(
    90deg,
    rgba(107 182 207 / 90%),
    rgba(25, 185, 209, 1) 70%
  ) !important;

  border-color: #18b8d1;
}

