.tech-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tech-card {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px;
  width: 140px;
  height: 140px;
  background-color: #fff;
  box-shadow: 5px 5px 60px rgb(235, 235, 235), -5px -5px 60px rgb(237, 237, 237);
  border-radius: 15px;
  transition: all 0.5s ease;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  transform-style: preserve-3d;
  animation: slideInUp 0.5s ease-out forwards;
}

@keyframes slideInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.circle {
  position: absolute;
  width: 70px;
  height: 70px;
  background-color: #ddd;
  border-radius: 50%;
  transition: all 0.5s ease;
  z-index: 0;
  margin-top: 0.5rem;
}

.tech-icon {
  font-size: 40px;
  z-index: 1;
  color: white;
  background-color: transparent;
  padding: 5px;
}

.content {
  margin-top: 20px;
  font-size: 18px;
  font-weight: 500;
  transition: color 0.5s ease;
  z-index: 1;
}

.tech-card:hover .circle {
  width: 152px;
  height: 187px;
  border-radius: 15px;
}

.icon-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.tech-card:hover .content {
  color: white;
}
.tech-card:hover .tech-icon {
  color: white;
}

to {
  transform: translateY(0);
  opacity: 1;
}

.ios .circle {
  background-color: #000 !important;
}

.android .circle {
  background-color: #3ddc84 !important;
}

.native .circle {
  background-color: #61dafb !important;
}

.flutter .circle {
  background-color: #02569b !important;
}

.ionic .circle {
  background-color: #3880ff !important;
}

.swift .circle {
  background-color: #fa7343 !important;
}

.kotlin .circle {
  background-color: #7f52ff !important;
}

.objective-c .circle {
  background-color: #4385f4 !important;
}

.xamarin .circle {
  background-color: #3498db !important;
}

.angularjs .circle {
  background-color: #dd1b16 !important;
}

.typescript .circle {
  background-color: #3178c6 !important;
}

.reactjs .circle {
  background-color: #61dafb !important;
}

.vuejs .circle {
  background-color: #42b883 !important;
}

.nextjs .circle {
  background-color: #000 !important;
}

.gatsby .circle {
  background-color: #663399 !important;
}

.javascript .circle {
  background-color: #dbc206 !important;
}

.html5 .circle {
  background-color: #e34f26 !important;
}

.css3 .circle {
  background-color: #1572b6 !important;
}

.tailwind .circle {
  background-color: #4dc0b5 !important;
}
.dotnet .circle {
  background-color: #4dc0b5 !important;
}
.php .circle {
  background-color: #1572b6 !important;
}
.java .circle {
  background-color: #104e48 !important;
}
.nodejs .circle {
  background-color: #058a63 !important;
}
.python .circle {
  background-color: #9fd02c !important;
}

.laravel .circle {
  background-color: #4dc0b5 !important;
}
.codeigniter .circle {
  background-color: #b94e4e !important;
}
.django .circle {
  background-color: #1572b6 !important;
}
.mean .circle {
  background-color: #104e48 !important;
}
.cakephp .circle {
  background-color: #058a63 !important;
}

@media (max-width: 600px) {
  .tech-card {
    width: 120px;
    height: 120px;
  }

  .circle {
    width: 40px;
    height: 40px;
  }

  .tech-icon {
    font-size: 30px;
  }

  .content {
    font-size: 14px;
  }
}
