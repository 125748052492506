#home {
  background-image: linear-gradient(
    0deg,
    rgb(16 152 179) 0%,
    rgb(145 213 200 / 61%) 100%
  );
}

.header-texts {
  margin-top: 3rem;
  padding: 74px 0 35px;
  position: relative;
  z-index: 6;
}

.header-texts h1,
.header-texts h2 {
  color: #fff;
  display: inline-block;
  margin-bottom: 20px;
}

.cd-headline.clip .cd-words-wrapper,
.cd-headline.loading-bar .cd-words-wrapper,
.cd-headline.slide .cd-words-wrapper,
.cd-headline.type .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}

.cd-headline.clip span,
.cd-headline.loading-bar span,
.cd-headline.slide span {
  padding: 0.2em 0;
  display: inline-block;
}

.cd-words-wrapper {
  position: relative;
  font-size: 1.5rem;
  color: #000;
}

.typing-text {
  display: inline-block;
  border-right: 2px solid #000;
  animation: typing 2s steps(12), erase 2s steps(12) 2s;
}

.blinking-cursor {
  display: inline-block;
  font-weight: bold;
  animation: blink 0.7s step-end infinite;
}

.button-position {
  margin-top: 2.5rem !important;
}
@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 12ch;
  }
}

@keyframes erase {
  from {
    width: 12ch;
  }

  to {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.header-laptop-mockup {
  background: url(https://demo.themenio.com/appsland/assets/images/macbook.png)
    no-repeat top center;
  background-size: 100%;
  position: absolute;
  padding: 5% 13% 11%;
  z-index: 9;
  top: 12rem;
}

.header-texts .button {
  background: #ed9443;
  color: #fff;
}

.provides-better {
  background: linear-gradient(115deg, rgb(68 198 222), rgb(255 77 77 / 88%));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
}

.lead {
  font-size: 1.5rem;
  font-weight: 300;
  color: rgba(62, 33, 33, 0.88);
}


.carb-size {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 575px) {
  .header-curbed:after {
    top: 31rem !important;
  }
}

@media screen and (max-width: 768px) {
  .header-curbed:after {
    top: 34rem !important;
  }
}

@media screen and (min-width: 769px) and (max-width: 1025px) {
  .header-curbed:after {
    top: 38rem !important;
  }
}
  
.header-laptop-mockup {
  background: url(https://demo.themenio.com/appsland/assets/images/macbook.png)
  no-repeat top center;
  background-size: 100%;
  position: relative;
  padding: 5% 13% 11%;
  z-index: 9;
}

@media (max-width: 768px) {
  .header-texts {
    margin-top: 0%;
    padding: 35px 0;
  }

  .display-4 {
    font-size: 2.5rem;
  }
  .text-size {
    font-size: 40px !important;
  }
  .lead {
    font-size: medium;
    padding: 0% 30px;
  }
}

@media (max-width: 576px) {
  .header-laptop-mockup {
    margin-bottom: 2rem;
    top: 5rem;
  }

  .header-texts {
    margin-top: 0%;
    padding: 0%;
  }
  .display-4 {
    font-size: 1.5rem;
  }
  .lead {
    font-size: small;
    padding: 0% 30px;
  }
  .text-size {
    font-size: 25px !important;
  }
}
.product-descrption {
  line-height: 1.5rem;
}