.blog-card {
    position: relative;
    width: 100%;
    max-width: 320px;
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
    padding: 0;
    height: 400px;
    max-height: 400px;
  }
  
  .blog-image img {
    width: 100%;
    height: 235px;
    object-fit: cover;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  
  .blog-content {
    padding-left: 0.5rem;
    padding-bottom: 0.5rem;
    transition: all 0.3s ease;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    background: white;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  
  .blog-title {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 10px;
    white-space: nowrap;
    text-wrap: auto;
  }
  
  .blog-date {
    font-size: 14px;
    color: #666;
    margin-bottom: 8px;
  }
  
  .blog-tags {
    display: flex;
    flex-wrap: wrap;
  }
  
  .tag {
    background: #007bff;
    color: white;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 12px;
    font-weight: bold;
  }
  
  .blog-description {
    font-size: 14px;
    color: #444;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .blog-card:hover .blog-description {
    opacity: 1;
  }

  .blog-read-more {
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    color: #3e85c6;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .blog-read-more:hover {
    color: #0056b3;
  }
  
  .arrow {
    margin-left: 5px;
    transition: transform 0.3s ease-in-out;
    font-size: larger;
  }
  
  .blog-read-more:hover .arrow {
    transform: translateX(5px); 
  }
  .tag {
    background: white; 
    color: #333;
    font-size: 12px;
    padding: 6px 12px;
    border-radius: 15px;
    font-weight: bold;
    border: 1px solid #ddd;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  }
  .tag-icon {
    font-size: 16px; 
  }