.feature-icon {
    color: #a7d5ff ;
    width: 3rem;
    height: 3rem;
    object-fit: contain;
    position: absolute;
    left: 10px;
    top: 13px;
    border-radius: 8px;
    background-color: #fbfbff;
    padding: 10px;
} 
