.back-to-top {
  position: fixed;
  right: 30px;
  top: auto !important;
  bottom: 30px;
  display: flex;
  width: 45px;
  height: 44px;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  z-index: 9999;
}

@media (max-width: 540px) {
  .back-to-top {
    display: none !important;
  }
}
