.video-container {
    position: relative;
    width: 100%;
  }
  
  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #3e85c6a1;
    color: white;
    border: none;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    font-size: 50px;
    cursor: pointer;
    outline: none;
  }
  
  .play-button:hover {
    background-color: rgb(0 0 0 / 49%);
  }
  
  .video-controls {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
    width: 100%;
  }
  
  .control-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .control-button:hover {
    background-color: #0056b3;
  }
  
  /* Progress bar container */
  .progress-container {
    position: relative;
    width: 100%;
    height: 5px;
    background: #ddd;
    border-radius: 5px;
    overflow: hidden;
  }
  
  /* Smooth progress bar */
  .progress-bar {
    position: absolute;
    height: 100%;
    width: 0%;
    background: #3e85c6a1;
    transition: width 0.2s linear, background 0.3s ease-in-out;
  }
  
  /* Custom thumb styling */
  input[type='range'] {
    -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  }
  
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 5px;
    background: transparent;
  }
  
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0px;
    height: 0px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
  
  input[type='range']::-webkit-slider-thumb:hover {
    background: #3e85c6a1;
  }
  
  .time {
    font-size: 14px;
    font-weight: bold;
  }
  input[type='range']::-moz-range-thumb {
    width: 0px;
    height: 0px;
    background: transparent;
    border: none;
    cursor: pointer;
  }