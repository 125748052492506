.full-width-section {
  width: 100%;
  background-color: white;
  padding: 60px 0;
}

.section-header {
  text-align: center;
  margin-bottom: 40px;
  font-size: 36px;
  font-weight: 700;
  color: #2a2a2a;
}

.tab-container {
  max-width: 1200px;
  margin: 0 auto;
}

.nav-tab {
  display: block;
  padding-top: 5px;
  background-color: transparent;
  color: #333;
  margin-bottom: 15px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  text-align: left;
}

.nav-tab:hover,
.nav-tab.active {
  background-color: #fff;
  transform: translateX(10px);
}

.tab-description {
  font-size: 14px;
  margin-top: 8px;
  color: #6c757d;
}

.tab-content {
  background-color: #fff;
  border-radius: 12px;
  height: fit-content;
}

.tab-image-wrapper {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  max-width: 700px;
  position: relative;
  overflow: hidden;
}

.tab-laptop-mockup {
  background: url(https://demo.themenio.com/appsland/assets/images/macbook.png) no-repeat top center;
  background-size: 100%;
  padding: 3% 13% 11%  !important;
  position: relative;
  z-index: 0;
  height: 500px;
}

.tab-image {
  width: 100%;
  position: absolute;
  opacity: 0;
  transform: translateX(100%);
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  border-radius: 12px;
}

.tab-image.active {
  opacity: 1;
  transform: translateX(0);
}

.tab-image.slide-left {
  transform: translateX(-100%);
}

.tab-image.slide-right {
  transform: translateX(100%);
}

@media (max-width: 767px) {
  .section-header {
    font-size: 28px;
  }

  .tab-image-wrapper {
    height: 300px;
  }

  .tab-description {
    font-size: 12px;
  }
  .tab-content {
    width: 100%;
    margin-right: 0% !important;
    padding: 0 12px !important;
  }
  .tab-laptop-mockup {
    padding: 1rem 3rem;
  }
  .nav-tab {
    padding: 15px;
    margin-right: 0%;
  }
} 

