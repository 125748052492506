.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 800px;
  height: 90vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

h3.card-title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1rem;
  text-align: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  color: #888;
  transition: color 0.3s ease;
  font-family: 'bootstrap-icons';
}

.close-button:hover {
  color: #ff0000;
}

form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.name-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.form-check {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.btn-block {
  width: 100%;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  border-radius: 4px;
  border: none;
}

.btn-primary:hover,
.btn-secondary:hover {
  opacity: 0.9;
}

.no-scroll {
  overflow: hidden;
}

@media (max-width: 450px) {
  .name-group {
    display: inline;
  }
}

