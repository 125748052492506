.nopage-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  padding: 0 20px;
}

.nopage {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 50vh;
  width: 70vw;
  max-width: 700px;
  max-height: 500px;
  margin: 0 auto;
  position: relative;
}

.nopage-container img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

@media (max-width: 1200px) {
  .nopage {
    height: 45vh;
    width: 75vw;
  }
}

@media (max-width: 1024px) {
  .nopage {
    height: 40vh;
    width: 80vw;
  }
}

@media (max-width: 768px) {
  .nopage {
    height: 35vh;
    width: 85vw;
  }
}

@media (max-width: 480px) {
  .nopage {
    height: 30vh;
    width: 90vw;
  }
}
