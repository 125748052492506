.section.process_section {
  padding: 50px 0;
}

.all_heading .section_heading {
  font-size: 2rem;
  margin-bottom: 20px;
}

.all_heading .section_heading span {
  color: #3e85c6;
}

.subtext {
  font-size: 1.1rem;
  color: #555;
}

.common_list ul {
  padding: 0;
  list-style: none;
}

.card-hover-effect {
  position: relative;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 10px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.card-hover-effect:hover {
  transform: translate(4px, -4px) scale(1.03);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.1),
    -6px 6px 20px rgba(0, 0, 0, 0.15);
}

.card-body {
  transition: color 0.4s ease;
}

.card-hover-effect:hover .title,
.card-hover-effect:hover .para {
  color: black;
}
