.copyright {
  border-top: 2px solid rgb(9 8 8 / 8%);
  background: rgb(245 245 245) ;
  position: absolute;
}
.text-mayora {
  color:rgb(63 133 198);
  text-decoration: none !important;
  cursor: pointer;
  font-weight: bold;
}
.fas.fa-copyright {
  color: rgb(63 133 198); 
}


@media (max-width: 450px) {
  .footer-text {
    display: flex;
    justify-content: center;
  }
}
