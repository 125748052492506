.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(16 149 242 / 9%);
  color: var(--bs-primary);
  transition: 0.5s;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  background: var(--bs-primary);
  color: var(--bs-white) !important;
}

.testimonial {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.testimonial .testimonial-carousel.owl-carousel {
  position: relative;
  padding: 0 35px;
  transition: 0.5s;
}

.testimonial .testimonial-carousel .owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 65px;
  height: 65px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(102, 16, 242, 0.09);
  color: var(--bs-primary);
  transition: 0.5s;
}

.testimonial .testimonial-carousel .owl-nav .owl-next {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 65px;
  height: 65px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(102, 16, 242, 0.09);
  color: var(--bs-primary);
  transition: 0.5s;
}

.testimonial .testimonial-carousel .owl-nav .owl-prev:hover,
.testimonial .testimonial-carousel .owl-nav .owl-next:hover {
  background: var(--bs-primary);
  color: var(--bs-white) !important;
}

.testimonial-carousel .owl-dots {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dots .owl-dot img {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 15px;
  transition: 0.5s;
}

.testimonial-carousel .owl-dots .owl-dot.active img {
  width: 70px;
  height: 70px;
  border-radius: 40px;
  border: 4px solid var(--bs-secondary);
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: large;
  font-weight: 600;
}

@media (min-width: 900px) {
  .testimonial
    .testimonial-carousel
    .testimonial-item
    .testimonial-inner
    p.fs-7 {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .testimonial .testimonial-carousel.owl-carousel {
    padding: 0;
  }

  .testimonial .testimonial-carousel .owl-nav .owl-prev {
    margin-top: -250px;
    margin-left: -15px;
  }

  .testimonial .testimonial-carousel .owl-nav .owl-next {
    margin-top: -250px;
    margin-right: -15px;
  }
}
