.mt {
  margin-top: 6rem;
}

.RotateMoveLeft {
  position: relative;
  animation: RotateMoveLeft 10s linear infinite;
  -webkit-animation: RotateMoveLeft 10s linear infinite;
}

@keyframes RotateMoveLeft {
  0% {
    left: 0px;
  }
  50% {
    left: 40px;
  }
  100% {
    left: 0px;
  }
}

.about-section {
  padding: 60px 0;
}

.about-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.about-section img {
  width: 100%;
  height: auto;
}

.industries-section h2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
}

.industry-card {
  background: linear-gradient(145deg, #d3d3d35c, #ffffff);
  padding: 50px 20px;
  margin: 0px;
  text-align: center;
  transition: all 0.3s ease;
}

.industry-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.industry-card:hover .industry-name {
  color: #5190d3;
}

.icon-container {
  font-size: 40px;
  transition: color 0.3s ease;
}

.industry-name {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

@media (max-width: 1024px) {
  .about-section h2 {
    font-size: calc(1.375rem + 1.5vw);
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .industry-card {
    padding: 15px 15px;
  }

  .icon-container {
    font-size: 35px;
  }

  .industry-name {
    font-size: 14px;
  }
  .about-section img {
    margin-bottom: 20px;
    margin-left: 3.5rem;
  }
}
@media (max-width: 450px) {
  .about-section img {
    margin-left: 3rem;
  }
}
.icon-container {
  font-size: 4rem;
}

.our-story-title {
  color: rgb(0, 195, 255);
}
.highlight {
  color: #3e85c6;
}

