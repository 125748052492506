.footer {
  background-color:rgb(245 245 245) ;
}

.footer .footer-item a {
  color: #6b6e72;
  transition: 0.5s;
  position: relative;
  font-size: 14px;
}

.footer .footer-item a::after {
  content: '';
  position: absolute;
  top: 0;
  left: -10px;
  height: 100%;
  width: 2px;
  background-color: #3f84c9;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s ease-in-out;
}

.footer .footer-item a:hover::after {
  transform: scaleY(1);
}

.footer .footer-item p {
  line-height: 35px;
}

.footer .footer-item a:hover {
  letter-spacing: 2px;
  color: #2d6aa5;
  font-weight: 500;
}

.footer-item {
  width: 20%;
  padding: 15px;
}

.footer-content a {
  margin-bottom: 10px;
  color: #333;
}

.footer-content a:hover {
  text-decoration: none;
  color: #007bff;
}

.mayora-mail {
  display: flex;
  align-items: center;
}

.mayora-mail i {
  margin-right: 8px;
}

.mayoragmail {
  white-space: nowrap;
}

.icon-size {
  border: none;
  height: 34px;
  width: 34px;
}

.icon-size:hover {
  border: 1px solid #0062cc;
}

.sitemap-link {
  cursor: pointer;
  color: var(--bs-dark);
  transition: 0.5s;
  text-decoration: none;
  margin-right: 12px;
  display: flex;
  gap: 1rem;
}

.sitemap-link:hover {
  letter-spacing: 2px;
  color: #3f84c9;
}

@media (min-width: 1025px) {
  .footer .footer-item a:hover {
    letter-spacing: 0.2px;
  }
}

@media (max-width: 1024px) {
  .footer-item {
    padding: 10px;
  }
  .mayora-mail {
    flex-direction: row;
    align-items: flex-start;
  }

  .mayoragmail {
    white-space: normal;
    word-break: break-all;
  }
  .fas.fa-envelope {
    padding-top: 0.6rem;
  }
}

@media (max-width: 990px) {
  .footer-item {
    width: 50%;
    padding-left: 2rem;
  }
}

@media (max-width: 450px) {
  .footer-item {
    width: 100%;
  }
}

.content-text {
  color: #6b6e72;
  font-size: 14px;
  line-height: 30px;
}
