.product-tile {
  box-shadow: 0 2px 5px grey;
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;
  max-height: 500px;
  height: 300px;
}

.product-tile {
  cursor: pointer;
}

.product-tile::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  transform: skewX(-25deg);
  transition: all 0.5s ease;
  z-index: 1;
}

.product-tile:hover::before {
  left: 100%;
}

.image-wrapper {
  background-color: rgba(248, 245, 245, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  bottom: 0rem;
}

.product-image {
  width: 80%;
  height: auto;
  object-fit: cover;
  transition: transform 0.3s ease;
  position: relative;
  top: 0.5rem;
  z-index: 0;
}

.product-tile:hover .product-image {
  transform: translateY(-4%);
}

.product-tile:hover .product-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  height: 100%;
  transform: skewX(-25deg);
  transition: all 0.5s ease;
}

.product-tile:hover .product-image::before {
  left: 100%;
}

.product-details {
  text-align: center;
  padding-top: 1rem;
}

.product-title {
  font-size: 1.2rem;
  color: #333;
  font-weight: 600;
  text-transform: capitalize;
}

.product-description {
  color: #777;
  margin-bottom: 1rem;
}

.product-link {
  color: #007bff;
  margin: 0 0.5rem;
  font-size: 0.9rem;
}

@media (max-width: 768px) {
  .product-tile {
    margin-bottom: 1rem;
  }
}
