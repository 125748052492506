.technology-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 40px 20px;
  background-color: #f9f9f9;
}

.technology-top-image {
  position: relative;
  width: 100%;
  max-width: 90vw;
}

.vector-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
}
.icon-class {
  color: white !important;
  width: 7rem;
  height: 6rem;
  object-fit: contain;
  position: absolute;
  left: 10px;
  top: 13px;
}
.logo {
  position: absolute;
  top: 0px;
  width: 80px;
  height: auto;
  background: transparent;
}

.fallback-icon {
  object-fit: contain;
  position: absolute;
  left: 20px;
  top: -8px;
  font-size: 5rem;
  color: white !important;
  justify-content: center;
}

.logo-background {
  height: 35rem;
  width: 34rem;
  -webkit-mask-image: radial-gradient(circle at bottom right, transparent 20%, black 40%);
  mask-image: radial-gradient(circle at bottom right, transparent 80%, black 40%);
}

.highlight {
  color: #007bff;
  font-size: 28px;
  font-weight: bold;
}

div.col-lg-6.order-lg-2::-webkit-scrollbar {
  width: 8px;
}

div.col-lg-6.order-lg-2::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

div.col-lg-6.order-lg-2::-webkit-scrollbar-thumb {
  background-color: #3e85c6;
  border-radius: 10px;
}

div.col-lg-6.order-lg-2::-webkit-scrollbar-thumb:hover {
  background-color: #3e84c6ce;
}
@media (max-width: 992px) {
  .icon-class {
    width: 7rem;
    height: 6rem;
    left: 15px;
    top: 14px;
  }

  .logo-background {
    height: 35rem;
    width: 34rem;
    -webkit-mask-image: radial-gradient(circle at bottom right, transparent 20%, black 40%);
    mask-image: radial-gradient(circle at bottom right, transparent 80%, black 40%);
  }

  .fallback-icon {
    object-fit: contain;
    position: absolute;
    left: 32px;
    top: -25px;
    font-size: 8rem;
    color: white !important;
    justify-content: center;
}
}

@media (max-width: 768px) {
  .logo {
    width: 50px;
    top: 2px;
    left: 2px;
  }

  .icon-class {
    width: 6rem;
    height: 6rem;
    left: 12px;
    top: 8px;
}

  .logo-background {
    height: 35rem;
    width: 34rem;
    -webkit-mask-image: radial-gradient(circle at bottom right, transparent 20%, black 40%);
    mask-image: radial-gradient(circle at bottom right, transparent 80%, black 40%);
  }

  .fallback-icon {
    object-fit: contain;
    position: absolute;
    left: 15px;
    top: -25px;
    font-size: 6rem;
    color: white !important;
    justify-content: center;
}
}

@media (max-width: 575px) {
  .logo {
    width: 50px;
    top: 2px;
    left: 2px;
  }

  .icon-class {
    width: 3rem;
    height: 3rem;
    left: 8px;
    top: 9px;
} 

  .logo-background {
    height: 35rem;
    width: 34rem;
    -webkit-mask-image: radial-gradient(circle at bottom right, transparent 20%, black 40%);
    mask-image: radial-gradient(circle at bottom right, transparent 80%, black 40%);
  }

  .fallback-icon {
    object-fit: contain;
    position: absolute;
    left: 12px;
    top: -18px;
    font-size: 5rem;
    color: white !important;
    justify-content: center;
}
}

.logo-background.ios { background-color: #000 !important; }
.logo-background.android { background-color: #3ddc84 !important; }
.logo-background.react-native { background-color: #61dafb !important; }
.logo-background.flutter { background-color: #02569b !important; }
.logo-background.ionic { background-color: #3880ff !important; }
.logo-background.swift { background-color: #fa7343 !important; }
.logo-background.kotlin { background-color: #7f52ff !important; }
.logo-background.objective-c { background-color: #4385f4 !important; }
.logo-background.xamarin { background-color: #3498db !important; }
.logo-background.angularjs { background-color: #dd1b16 !important; }
.logo-background.typescript { background-color: #3178c6 !important; }
.logo-background.reactjs { background-color: #61dafb !important; }
.logo-background.vuejs { background-color: #42b883 !important; }
.logo-background.nextjs { background-color: #000 !important; }
.logo-background.gatsby { background-color: #663399 !important; }
.logo-background.javascript { background-color: #dbc206 !important; }
.logo-background.html5 { background-color: #e34f26 !important; }
.logo-background.css3 { background-color: #1572b6 !important; }
.logo-background.tailwind { background-color: #4dc0b5 !important; }
.logo-background.dotnet { background-color: #4dc0b5 !important; }
.logo-background.php { background-color: #1572b6 !important; }
.logo-background.java { background-color: #104e48 !important; }
.logo-background.nodejs { background-color: #058a63 !important; }
.logo-background.python { background-color: #9fd02c !important; }
.logo-background.laravel { background-color: #4dc0b5 !important; }
.logo-background.codeigniter { background-color: #b94e4e !important; }
.logo-background.django { background-color: #1572b6 !important; }
.logo-background.mean { background-color: #104e48 !important; }
.logo-background.cakephp { background-color: #058a63 !important; }
