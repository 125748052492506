.service {
  padding: 1rem 0;
}

.service .card-effect {
  position: relative;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service .card-effect:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-radius: inherit;
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.05), rgba(255, 255, 255, 0.05));
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.service .card-effect:hover {
  transform: translate(4px, -4px) scale(1.03);
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.1), -6px 6px 20px rgba(0, 0, 0, 0.15);
}

.service .card-effect:hover:before {
  opacity: 1;
}

.service .card-effect:hover .card-title,
.service .card-effect:hover .card-text {
  color: #000;
}

.service-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.service-image:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .service-item-container {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

@media (max-width: 576px) {
  .service-item-container {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
