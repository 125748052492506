.technology-section {
  text-align: center;
  padding: 50px;
}

.tech-navbar ul {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.tech-navbar li {
  flex-grow: 1;
  text-align: center;
  padding: 15px 0;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tech-navbar li::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.tech-navbar li:hover {
  transform: scale(1.05);
}

.tech-navbar .active::after {
  width: 100%;
  height: 3px;
  background-color: #007bff;
}

.tech-content {
  margin-top: 30px;
}

@media (max-width: 768px) {
  .tech-navbar ul {
    font-size: 15px !important;
    gap: 1rem !important;
    display: flex;
    flex-wrap: wrap;
    border-bottom: none;
  }
  .tech-navbar li {
    border-bottom: 1px solid black;

  }
  .technology-section {
    padding: 20px !important;
  }
}

@media (max-width: 540px) {
  .tech-navbar ul {
    font-size: 15px !important;
    gap: 1rem !important;
    display: flex;
    flex-wrap: wrap;
  }
  .tech-navbar li {
    border-bottom: 1px solid black;

  }
  .technology-section {
    padding: 20px !important;
  }
}
