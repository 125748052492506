.process-card {
  width: 100%;
  height: 250px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 5px;
  background-color: rgb(241 248 255);
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  position: relative;
}

.card-title {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 15px;
}

.card-list {
  list-style-type: none !important;
  padding-left: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  white-space: normal !important; 
  word-wrap: break-word !important;
  overflow-wrap: break-word !important; 
}

.card-list-item {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  font-size: 1rem !important;
  color: #555 !important;
  margin-bottom: 10px !important;
  text-align: left !important;
  gap: 10px !important;
  word-wrap: break-word !important; 
  overflow-wrap: break-word !important;
  white-space: normal !important;
}

