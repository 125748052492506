.contact {
  position: relative;
  overflow: hidden;
}

.contact::after,
.contact::before {
  content: '';
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 200px;
  border: 60px solid rgba(108, 212, 239, 0.51);
  background: transparent;
  z-index: -2;
}

.contact::after {
  top: 10%;
  left: -200px;
  animation: ContactMoveLeft 100s linear infinite;
}

.contact::before {
  top: 10%;
  right: -200px;
  animation: ContactMoveRight 100s linear infinite;
}

.btn.btn-primary:hover {
  background: linear-gradient(0deg,
    rgba(0, 172, 238, 1) 0%,
      rgba(2, 126, 251, 1) 100%) !important;
  color: #fff !important;
}

@keyframes ContactMoveLeft {
  0% {
    left: 0;
    top: 10%;
  }

  25% {
    left: 90%;
    top: 100px;
  }

  50% {
    left: 100%;
    top: 50%;
  }

  75% {
    left: 10%;
    top: 80%;
  }

  100% {
    left: 0;
    top: 10%;
  }
}

@keyframes ContactMoveRight {
  0% {
    right: 0;
    top: 10%;
  }

  25% {
    right: 90%;
    top: 100px;
  }

  50% {
    right: 100%;
    top: 50%;
  }

  75% {
    right: 10%;
    top: 80%;
  }

  100% {
    right: 0;
    top: 10%;
  }
}

@media (max-width: 1440px) {
  .width-content {
    width: 300px;
  }
}

@media (max-width: 991px) {
  .contact .col-lg-6 {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .contact .row.g-3 .col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contact .form-floating {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .contact .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .contact .form-floating {
    width: 100%;
  }

  .display-3 {
    font-size: 2.5rem;
  }

  .display-5 {
    font-size: 2rem;
  }

  .contact iframe {
    height: 300px;
  }
}

@media (max-width: 575px) {
  .contact .container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .contact iframe {
    height: 250px;
  }

  .contact .btn {
    font-size: 1rem;
    padding: 12px 0;
  }
}

@media (max-width: 450px) {
  .contact .col-lg-6 {
    margin-bottom: 20px;
  }

  .contact iframe {
    height: 200px;
    width: 100%;
  }

  .contact .container {
    padding: 5px;
  }

  .text-email {
    word-break: break-all !important;
    width: 180px;
    max-width: 250px;
  }
}

.contect-icon {
  color: var(--bs-primary);
}

.contact-us-text {
  color: var(--bs-primary);
}

.margin-left {
  margin-left: 1rem;
}

.content-details-width {
  width: 300px;
  word-break: word-break !important;
}

.font-weight{
  font-weight: 600;
}