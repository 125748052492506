  .justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.text-color {
  color: #f74780 !important;
}

.btn-main {
  background-color: #f74780 !important;
  color: #ffffff !important;
  border: none;
}

.btn-round-full {
  border-radius: 50px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 0.25rem;
}

.mr-2 {
  margin-right: 0.25rem !important;
}
.mr-3 {
  margin-right: 0.75rem !important;
}
.bg-gray {
  background-color: #f8f9fa !important;
}
.media {
  display: flex;
}

.media-body {
  flex: 1;
}

.social-icons a {
  display: inline-block;
  margin-right: 0.5rem;
}

.tags a {
  background: #f5f8f9;
  display: inline-block;
  margin: 0 10px 10px 0;
  border: 1px solid #eee;
  border-radius: 38px;
  font-size: 15px;
  padding: 8px 23px;
  text-transform: capitalize;
}

.tags a:hover {
  text-decoration: underline;
}
.rounded-0 {
  border-radius: 0px !important;
}

@media (max-width: 1024px) {
  .col-lg-8,
  .col-lg-4 {
    max-width: 100%;
  }
}

@media (max-width: 991.98px) {
  .post-prev,
  .posts-next {
    text-align: center;
    margin-bottom: 1rem;
  }

  .posts-nav {
    flex-direction: column;
  }

  .social-icons {
    text-align: center;
  }
}

.border-remove {
  border: none;
  width: 34px;
  height: 34px;
}

.border-remove:hover {
  border: 1px solid #0062cc;
}

@media (max-width: 767.98px) {
  .single-blog-item img {
    width: 100%;
  }

  .sidebar-wrap {
    margin-top: 2rem;
  }
}
.posts-nav {
  background-color: white;
  padding: 3rem;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 992px) {
  .posts-nav {
    display: flex;
  }
}

@media (max-width: 768px) {
  .posts-nav {
    display: block;
  }
}
.nav-posts-title {
  line-height: 25px;
  font-size: 18px;
}

.comment-meta {
  margin-top: 1.5rem;
}

@media (min-width: 768px) {
  .comment-meta {
    margin-top: 0;
    float: right;
  }
}

@media (min-width: 992px) {
  .comment-meta {
    margin-top: 0;
    float: right;
  }
}

.btn-round-full {
  border-radius: 50px;
}
.form-group {
  margin-bottom: 1rem;
}

.submit-btn {
  display: inline-block !important;
  font-size: 14px !important;
  font-size: 0.8125rem !important;
  font-weight: 400 !important;
  padding: 1rem 2.5rem !important;
  text-transform: uppercase !important;
  border-radius: 0 !important;
  transition: all 0.2s ease !important;
}
.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}
.tags span {
  background: #f5f8f9;
  display: inline-block;
  padding: 8px 23px;
  border-radius: 38px;
  margin: 0 10px 10px 0;
  border: 1px solid #eee;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 600;
}

.tags span:hover {
  background-color: #3e85c6;
  color: white;
}
.digital-markter {
  color: #3e85c6;
}

.sticky-column {
  position: -webkit-sticky;
  position: sticky;
  top: 60px; 
  padding: 10px;

}
.recent-blogs-card {
  height: auto;
  background-color : #f6f6f6;
  padding: 20px;
}

@media (max-width: 768px) {
  .sticky-column {
    position: static; 
  }
}