.badge {
  background-color: #e9ecef !important;
  display: inline-block;
  border: 2px solid #3e85c6 ;
  color: #615555 !important ;
}
.badge:hover{
  background-color: #3e85c6 !important ;  
  color: white !important;
}

.blog-tags {
  height: auto;
}
