.features-list-tab .nav-tabs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 40px;
}

.features-list-tab .nav-tabs .nav-item {
  flex: 1 0 auto;
  max-width: 16.6666667%;
  text-align: center;
  margin-bottom: 10px;
}

.features-list-tab .nav-tabs .nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  background-color: #f8f9fa;
  border: none;
  padding: 10px;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 10px;
}

.features-list-tab .nav-tabs .nav-link.active,
.features-list-tab .nav-tabs .nav-link:hover {
  background-color: #3e85c6;
  color: #fff;
}

.features-list-tab .tab-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  color:#757575;
  padding: 15px;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.features-list-tab .tab-icon-item {
  font-size: 30px;
  margin-bottom: 10px;
}

.features-list-tab .tab-icon span {
  font-size: 14px;
  font-weight: bold;
}

.bg-fa7070,
.bg-00aeff,
.bg-c679e3,
.bg-eb6b3d,
.bg-f78acb {
  background-color: transparent !important;
}

.features-list-tab .nav-link:hover .tab-icon {
  transform: scale(1.1);
  color:#fff
}

.features-list-tab .nav-link.active .tab-icon {
  transform: scale(1.2);
  color:#fff
}

@media (max-width: 768px) {
  .features-list-tab .nav-tabs .nav-item {
    flex: 0 0 50%;
    max-width: 40% !important;
  }
  .features-list-tab .tab-icon-item {
    font-size: 24px;
  }
  .features-list-tab .tab-icon span {
    font-size: 11px;
  }
}

@media (max-width: 576px) {
  .features-list-tab .nav-tabs .nav-link {
    margin-bottom: 10px;
  }
  .features-list-tab .nav-tabs {
    margin-bottom: 0px;
  }
}
