.tab-fix {
  position: relative;
  overflow: hidden;
  max-width: 100%;
}
.software-screens {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.software-screens img {
  margin: 0%;
  width: 100%;
  display: block;
}

.has-carousel {
  margin: 0 auto;
}

.outer-carsouel {
  width: 65%;
  margin: 0 auto;
}

.item img {
  display: block;
  height: 600px;
  width: 100%;
  object-fit: cover;
}

/* .slick-next:before {
  font-family: 'slick';
  font-size: 30px;
  position: relative;

  left: 15px;
} */
/* .slick-prev:before {
  font-family: 'slick';
  font-size: 30px;

  position: relative;
  left: -15px;
} */
