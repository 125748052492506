.accordion {
  padding: 30px 20px;
  border-radius: 12px;
  transition: all 0.3s ease;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
}

.accordion-item:hover {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.accordion-header {
  margin: 0;
  background-color: #eaf2f9;
  font-size: 1.1rem;
  font-weight: 600;
  color: #4f4f4f;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.accordion-button {
  position: relative;
  color: #4a4a4a;
  background-color: transparent;
  padding: 1rem 1.25rem;
  border: 0;
  width: 100%;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.accordion-button::after {
  position: absolute;
  right: 1rem;
  transition: transform 0.3s ease;
}

.accordion-body {
  background-color: #f6f9fc;
  color: #5a5a5a;
  font-size: 1rem;
  max-height: 0;
  opacity: 0;
  transform: translateY(-10px);
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out;
}

.accordion-body.show {
  max-height: fit-content;
  opacity: 1;
  transform: translateY(0);
}

.accordion-button:not(.collapsed) {
  background-color: #eaf2f9;
  color: #007bff;
  border-bottom: 2px solid #007bff;
}

.accordion-button::after {
  content: '';
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  margin-left: auto;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23757575'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1rem;
  transition: transform 0.3s ease;
}

.accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.accordion-item:last-of-type .accordion-collapse {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

@media (max-width: 768px) {
  .accordion-button {
    padding: 1rem 1.25rem;
    font-size: 1.05rem;
  }
}

@media (max-width: 576px) {
  .accordion-button {
    font-size: 1rem;
    padding: 1rem 1.2rem;
  }
  .accordion {
    padding: 30px 10px;
  }
}
