html {
  scroll-behavior: smooth;
}

.custombutton-container {
  margin-bottom: 5rem !important;
}

#careerpost {
  padding: 2rem 5%;
  margin: 3rem 10rem;
  background: url('../../assets/img/home-job-background.png') no-repeat center
    center;
  background-size: cover;
  background-color: #e4f3ff;
  box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.careerpostcontainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}

.home-career-img img {
  max-width: 100%;
  height: 20rem;
  border-radius: 50%;
  background-color: rgb(10, 82, 128);
}

.home-job-title {
  font-size: 3rem;
  font-weight: 600;
  color: #0056b3;
  margin-bottom: 1rem;
}

.home-career-content {
  flex: 1;
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
}

.home-career-content h2 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.home-career-content p {
  font-size: 1rem;
  line-height: 1.6;
}

#home-contactus {
  padding: 2rem 1rem;
  background: url('../../assets//img//home-contact-background-image.jpeg')
    no-repeat center center;
  background-size: cover;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  text-align: center;
  -webkit-box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2);
  box-shadow: -20px -20px 0 0 rgb(12 65 98 / 50%);
  position: relative;
  margin: 2rem 10rem;
  top: 4rem;
  z-index: 1;
}

.home-contactus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.home-contactus-container h2 {
  font-size: 2rem;
  line-height: 1.4;
}

.contact-us-text {
  color: #0056b3;
}

.home-contactus-button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: 2px solid #007bff;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.home-contactus-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.home-contactus-button:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1550px) {
  .home-contactus-button {
    width: 11rem;
  }
}

@media (max-width: 1024px) {
  #careerpost {
    padding: 2rem 3rem;
    margin: 2rem 2rem;
  }

  .home-job-title {
    font-size: 1.8rem;
  }

  .home-career-img img {
    height: 16rem;
  }

  #home-contactus {
    padding: 2rem 1rem;
    margin: 2rem 2rem;
  }

  .home-contactus-button {
    font-size: 0.9rem;
    padding: 0.7rem 2rem;
    width: 12rem;
  }
}

@media (max-width: 768px) {
  .careerpostcontainer {
    flex-direction: column;
    text-align: center;
  }

  .home-career-content {
    max-width: 100%;
  }

  .home-career-img img {
    max-width: 80%;
    margin: 0 auto;
    height: auto;
  }

  .home-job-title {
    font-size: 1.5rem;
  }

  #home-contactus {
    padding: 2rem 1rem;
    margin: 2rem 2rem;
    display: flex;
    flex-direction: column;
  }

  .home-contactus-container h2 {
    font-size: 1.5rem;
  }

  .home-contactus-button {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem;
  }
}

@media (max-width: 480px) {
  .service-button {
    padding: 0.8rem 1.2rem;
    font-size: 1rem;
  }

  .home-job-title {
    font-size: 1.2rem;
  }

  .home-contactus-container h2 {
    font-size: 1.2rem;
  }

  .home-contactus-button {
    font-size: 0.8rem;
    padding: 0.6rem 1rem;
  }
}

.appsland {
  color: #3e85c6;
}

.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 80px;
  width: 80px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(212, 68, 99, 0.9);
  background: rgba(246, 246, 246, 0.75);
  border-radius: 50%;
  text-align: center;
  font-size: 2.25em;
}

.steps-screen {
  background: url(https://demo.themenio.com/appsland/assets/images/macbook.png)
    no-repeat top center;
  background-size: 100%;
  padding: 30px 90px 60px;
}

.imagebg {
  background: url(https://demo.themenio.com/appsland/images/pricing-bg.jpg);
  z-index: 2;
}

.pricing-section {
  padding: 15rem;
}

.section-head {
  padding: 50px 0 47px;
}

.steps-screen {
  background: url(https://demo.themenio.com/appsland/assets/images/macbook.png)
    no-repeat top center;
  background-size: 100%;
  padding: 30px 90px 60px;
}

.fearures-software-mockup {
  min-width: 1050px;
  background: url(https://demo.themenio.com/appsland/assets/images/macbook.png)
    no-repeat top center;
  background-size: 100%;
  padding: 7% 13% 11%;
  float: right;
  position: relative;
  z-index: 1;
  top: 3rem;
}

@media (max-width: 991px) {
  .fearures-software-mockup {
    min-width: 280px;
    padding: 5% 14% 21% 14%;
    float: left;
    position: relative;
  }

  .section-head {
    padding: 35px 0 47px;
  }
}

.gradiant-background,
.bg-gradiant.mfp-bg,
.box-icon,
.team-member .team-photo:after {
  background-image: -o-linear-gradient(157deg, #01dbb0 0%, #6e49d9 100%);
  background-image: linear-gradient(293deg, #01dbb0 0%, #6e49d9 100%);
}

.pull-right {
  float: right !important;
}

.heading-light {
  color: #fff;
}

.single-feature .bi,
.single-feature .ti {
  line-height: 60px;
  width: 60px;
  border-radius: 6px;
  text-align: center;
  font-size: 2.25em;
  color: rgba(212, 68, 99, 0.9);
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
}

.single-feature {
  position: relative;
  padding-left: 83px;
}

@media (min-width: 991px) and (max-width: 1201px) {
  .font-size-box {
    font-size: small;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .single-feature {
    text-align: center;
    padding-left: 0;
  }
  .font-size-box {
    font-size: small;
  }
  .content-padding {
    width: 50%;
    padding: 0 10%;
  }
  .fearures-software-mockup {
    top: 34%;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .content-padding {
    padding: 0 20%;
  }
  .font-size-box {
    font-size: small;
  }
  .fearures-software-mockup {
    top: 45%;
  }
}

@media only screen and (max-width: 991px) {
  .single-feature {
    text-align: center;
    padding-left: 0;
  }
  .single-feature .bi {
    position: relative;
    margin-bottom: 20px;
  }

  .font-size-box {
    font-size: small;
  }
}
