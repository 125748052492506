.sitemap {
  display: flex;
  padding: 100px;
}

.sitemap-links {
  line-height: 22px;
  text-decoration: none;
  transition: 0.5s;
  color: #565555;
  font-weight: 700;
}

.sitemap-links:hover {
  letter-spacing: 0.2px;
  color: var(--bs-primary);
  text-decoration: none;
}

.sitemap-container {
  margin: 0 auto;
}
.sitemap-container-img {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.sitemapimg {
  height: max-content;
  width: auto;
}
.sitemap-inner-li {
  margin-left: 2rem;
}

@media (max-width: 992px) {
  .sitemap-container-img {
    display: none;
  }
  .sitemap {
    padding: 50px;
  }
}
