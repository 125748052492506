.job-card {
  height: 100%;
  border-radius: 15px;
  transition: 0.5s;
}

.job-card:hover {
  background: var(--bs-white) !important;
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.2);
}

.second-column {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media (min-width: 768px) {
  .second-column {
    align-self: stretch;
  }
}

@media (max-width: 768px) {
  .applynow-button {
    margin: 0 auto !important;
  }
}
