.rts__job__details {
  background-color: var(--light-color);
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.section__padding {
  padding-top: 50px;
  padding-bottom: 120px;
}

.rts__section {
  position: relative;
}

.rts__tab .nav {
  border-bottom: none;
  display: flex;
  gap: 15px;
}

.h3 {
  color: #0b0d28 !important;
}

.rts__tab .nav .nav-link {
  padding: 15px 12px;
  background-color: #f1f1f1;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  color: #0b0d28 !important;
  letter-spacing: -0.5px;
  line-height: 1.2;
  margin-bottom: 2rem !important;
}

.rts__tab .nav .nav-link.active {
  background: var(--bs-primary) !important;
  color: #fff !important;
}

.list-style-checked {
  list-style: none;
  padding: 0;
}

.list-item {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.list-item i {
  margin-right: 8px;
  color: var(--bs-primary);
  font-size: 1rem;
}

.job__tags.job__details__tags {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}

.job__tags.job__details__tags a {
  padding: 5px 10px;
  border-radius: 6px;
  background-color: #f1f1f1 !important;
  color: #7d8087 !important;

  line-height: 1.3;
  text-transform: capitalize;
  transition: var(--transition);
  border: 1px solid #f1f1f1;
}

.rts__social a {
  display: inline-block;
  font-size: 20px;
  color: var(--rts-para);
  transition: var(--transition);
}

a:not([class]) {
  -webkit-text-decoration-skip: ink;
  text-decoration-skip-ink: auto;
}

.job__overview {
  background: linear-gradient(
    228.42deg,
    #fef2eb 0%,
    #f1f1f1 46.48%,
    #e4e0ea 100.36%
  );
  padding: 30px;
  border-radius: 10px;
}

.job__overview__content li {
  padding-bottom: 15px;
  margin-top: 15px;
  border-bottom: 1px solid rgba(125, 128, 135, 0.2);
}

.job__contact {
  background: linear-gradient(
    228.42deg,
    #fef2eb 0%,
    #f1f1f1 46.48%,
    #e4e0ea 100.36%
  );
  padding: 30px;
  border-radius: 10px;
}

.job__contact .search__item label {
  line-height: 1;
}

.search__item input {
  padding: 15px 20px;
  padding-left: 45px;
  border-radius: 4px;
  border: 0;
  position: relative;
  width: 100%;
}

.job__contact .search__item i {
  color: #939393;
  font-size: 15px;
}

.job__overview__content .left-text i {
  color: #0990e4 !important;
  font-size: 20px;
  margin-right: 1rem;
}

.search__item i {
  position: absolute;
  left: 40px;
  transform: translateY(-50%);
  color: var(--bg-primary) !important;
  margin-top: 26px;
  font-size: 16px;
}

[class^='rt-']:before,
[class*=' rt-']:before {
  font-family: 'rt-icons';
  font-style: normal;
  font-weight: normal;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.job__contact .apply__btn {
  background-color: var(--rts-primary);
  color: var(--rts-white);
  font-weight: 500;
}

@media (max-width: 400px) {
  .job__overview__content li {
    flex-direction: column !important;
    margin-right: 3rem;
  }
}
