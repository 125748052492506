.skills-container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .skills-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .skills-title {
    font-size: 36px;
    color: #333;
  }
  
  .skills-description {
    font-size: 18px;
    color: #666;
    margin-top: 10px;
  }
  
  
  .skills-list {
    display: flex;
    flex-wrap: wrap; 
    gap: 20px; 
    justify-content: center; 
  }
  
  .skill-item {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(63, 133, 198, 0.3);
    transition: transform 0.2s, box-shadow 0.2s;
    position: relative;
    flex-basis: calc(33.333% - 20px);
    max-width: calc(33.333% - 20px);
  }
  
  .skill-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(63, 133, 198, 0.5);
  }
  
  .skill-title {
    font-size: 24px;
    color: rgb(63, 133, 198);
  }
  
  .skill-description {
    display: flex;
    align-items: flex-start; 
    justify-content: flex-start; 
    gap: 10px; 
    color: #555;
    word-wrap: break-word; 
    text-align: left;
  }
  
  .skill-description i {
    flex-shrink: 0; 
    margin-top: 4px; 
  }
  
  .skill-description p {
    margin: 0; 
  }
  
  @media (max-width: 768px) {
    .skill-item {
      flex-basis: calc(50% - 20px); 
      max-width: calc(50% - 20px);
    }
  }
  
  @media (max-width: 540px) {
    .skills-title {
      font-size: 28px;
    }
  
    .skills-description {
      font-size: 16px;
    }
  
    .skill-item {
      flex-basis: 100%; 
      max-width: 100%;
    }
  }
  
  @media (min-width: 1200px) {
    .skill-item {
      flex-basis: calc(25% - 20px); 
      max-width: calc(25% - 20px);
    }
  }
  
  .section-title {
    font-size: 32px;
    font-weight: 700;
    color: #333;
    margin-bottom: 20px;
  }

  .roundpointer{
    top: 5px;
  }