.achivements-card {
  position: relative;
  width: 135px;
  height: 135px;
  border-radius: 50%;
  z-index: 1;
  transform: scale(0.9);
}
.achivements-card.visible {
    opacity: 1;
    transform: scale(1);
  }
  
.achivements-card::before {
  content: '';
  position: absolute;
  top: -8px;
  left: -8px;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #c6f5f2 0%, #6ab6e6 100%);
  z-index: -1;
}
.node-2 {
  width: 135px;
  height: 135px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: aliceblue;
}
.heading {
  display: flex;
  align-items: baseline;
  gap: 5px;
  margin-top: 0.5rem;
}
.text-large {
  font-size: 40px;
  font-weight: bold;
  color: rgba(31, 41, 55, 1);
}
.text-plus {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  color: rgba(255, 118, 117, 1);
}
.text-strength {
  font-size: 18px;
  font-weight: 500;
  color: rgba(85, 85, 85, 1);
  text-align: center;
}
@media (min-width: 1201px) {
  .achivements-card {
    width: 135px;
    height: 135px;
  }
  .achivements-card::before {
    width: 150px;
    height: 150px;
  }
  .node-2 {
    width: 135px;
    height: 135px;
  }
  .text-large {
    font-size: 40px;
  }
  .text-plus {
    font-size: 20px;
  }
  .text-strength {
    font-size: 18px;
  }
}
@media (max-width: 1200px) and (min-width: 768px) {
  .achivements-card {
    width: 120px;
    height: 120px;
  }
  .achivements-card::before {
    width: 135px;
    height: 135px;
  }
  .node-2 {
    width: 120px;
    height: 120px;
  }
  .text-large {
    font-size: 35px;
  }
  .text-plus {
    font-size: 18px;
  }
  .text-strength {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .achivements-card {
    width: 100px;
    height: 100px;
  }
  .achivements-card::before {
    width: 110px;
    height: 110px;
    top: -6px;
    left: -6px;
  }
  .node-2 {
    width: 100px;
    height: 100px;
  }
  .text-large {
    font-size: 28px;
  }
  .text-plus {
    font-size: 16px;
  }
  .text-strength {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .achivements-card {
    width: 90px;
    height: 90px;
  }
  .achivements-card::before {
    width: 90px;
    height: 90px;
    top: -6px;
    left: -6px;
  }
  .node-2 {
    width: 80px;
    height: 80px;
  }
  .text-large {
    font-size: 24px;
  }
  .text-plus {
    font-size: 14px;
  }
  .text-strength {
    font-size: 12px;
  }
}
