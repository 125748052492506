#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
  position: absolute;
}

#spinner.show {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: rgba(0, 0, 0, 0);
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border {
    animation-duration: 1.5s;
  }
}

