.carousel-container {
  width: 60%;
  max-width: 800px;
  height: 400px;
  margin: 0 auto;
}

.carousel-image {
  width: 100%;
  height: 400px;
  object-fit: contain;
}



@media (max-width: 500px) {
  .carousel-container {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .carousel-image {
    padding: 0 17px;
  }
}
