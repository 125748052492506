.testimonial-slider {
  padding: 2rem;
}

.testimonial-card {
  text-align: center;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0  0 20px 6px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  min-height: 280px;
}

.slick-center .testimonial-card {
  transform: scale(1.2);
  background-color: #007bff;
  color: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.card-content .message {
  font-style: italic;
  margin-bottom: 15px;
  font-size: medium;
}

.card-content .avatar {
  margin: 0 auto 15px;
  width: 80px;
  height: 80px;
}

.card-content .avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  border: 3px solid #fff;
}

.card-content .name {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 0;
}

@media (max-width: 400px) {
  .testimonial-card {
    text-align: center;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    padding: 12px;
    font-size: small;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  .card-content .message {
    font-style: italic;
    margin-bottom: 15px;
    font-size: small;
  }
}

@media (max-width: 768px) {
  .testimonial-card {
    text-align: center;
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    padding: 12px;
    font-size: small;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    min-height: 250px;
  }
  .card-content .message {
    font-style: italic;
    margin-bottom: 15px;
    font-size: small;
  }
  .slick-next::before,
  .slick-prev::before {
    display: none;
  }
}

@media (max-width: 450px) {
  .testimonial-slider {
    padding: 10px;
  }
}
