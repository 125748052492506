.technology-expertise-card::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  width: 0;
  background-color: #79b5f5;
  transition: width 0.4s ease;
}

.technology-expertise-card:hover::after {
  width: 100%;
}
.technology-expertise-card {
  position: relative;
  background-color: rgb(241, 248, 255);
  border: 1px solid transparent;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

}

.separator {
  display: block;
  width: 100%;
  height: 3px; 
  background-color: rgb(193, 221, 249); 
  margin: 10px 0; 
}
