html {
  scroll-behavior: smooth;
}

.custombutton-container {
  margin-bottom: 5rem !important;
}

#careerpost {
  padding: 2rem 5%;
  margin: 3rem 10rem;
  background: url('../../assets/img/home-job-background.png') no-repeat center center;
  background-size: cover;
  background-color: #e4f3ff;
  box-shadow: 0px 0px 8px -2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.careerpostcontainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1.5rem;
}

.home-career-img img {
  max-width: 100%;
  height: 20rem;
  border-radius: 50%;
  background-color: rgb(10, 82, 128);
}

.home-job-title {
  font-size: 3rem;
  font-weight: 600;
  color: #0056b3;
  margin-bottom: 1rem;
}

.home-career-content {
  flex: 1;
  max-width: 600px;
  text-align: center;
  margin: 0 auto;
}

.home-career-content h2 {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.home-career-content p {
  font-size: 1rem;
  line-height: 1.6;
}

#home-contactus {
  padding: 2rem 1rem;
  background: url('../../assets//img//home-contact-background-image.jpeg')no-repeat center center;
  background-size: cover;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  text-align: center;
  -webkit-box-shadow: -20px -20px 0 0 rgba(52, 58, 64, 0.2);
  box-shadow: -20px -20px 0 0 rgb(12 65 98 / 50%);
  position: relative;
  margin: 2rem 10rem;
  top: 4rem;
  z-index: 1;
}

.home-contactus-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.home-contactus-container h2 {
  font-size: 2rem;
  line-height: 1.4;
}

.contact-us-text {
  color: #0056b3;
}

.home-contactus-button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: 2px solid #007bff;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.home-contactus-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.home-contactus-button:focus {
  outline: none;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1550px) {
  .home-contactus-button {
    width: 11rem;
  }
}
  
@media (max-width: 1024px) {
  #careerpost {
    padding: 2rem 3rem;
    margin: 0 2rem 2rem 2rem ;
  }

  .home-job-title {
    font-size: 1.8rem;
  }

  .home-career-img img {
    height: 16rem;
  }
  #home-contactus {
    padding: 2rem 1rem;
    margin: 2rem 2rem;
  }
  .home-contactus-button {
    font-size: 0.9rem;
    padding: 0.7rem 2rem;
    width: 12rem;
  }
}

@media (max-width: 768px) {
  .careerpostcontainer {
    flex-direction: column;
    text-align: center;
  }

  .home-career-content {
    max-width: 100%;
  }

  .home-career-img img {
    max-width: 80%;
    margin: 0 auto;
    height: auto;
  }

  .home-job-title {
    font-size: 1.5rem;
  }

  #home-contactus {
    padding: 2rem 1rem;
    margin: 2rem 2rem;
    display: flex;
    flex-direction: column;
  }

  .home-contactus-container h2 {
    font-size: 1.5rem;
  }

  .home-contactus-button {
    font-size: 0.9rem;
    padding: 0.7rem 1.2rem;
  }
}

@media (max-width: 480px) {
  .service-button {
    padding: 0.8rem 1.2rem;
    font-size: 1rem;
  }

  .home-job-title {
    font-size: 1.2rem;
  }

  .home-contactus-container h2 {
    font-size: 1.2rem;
  }

  .home-contactus-button {
    font-size: 0.8rem;
    padding: 0.6rem 1rem;
  }
}
