.product-page {
  padding: 50px 0;
}

.product-card {
  position: relative;
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  height: 300px;
  color: white;
  overflow: hidden;
  transition: all 0.4s ease;
}

.product-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #0873ca 0%, rgba(0, 0, 0, 0.8) 100%);
  opacity: 0;
  z-index: 1;
  transition: opacity 0.6s ease;
}

.product-card:hover::before {
  opacity: 1;
}

.product-content {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2;
  opacity: 1;
  transition: opacity 0.4s ease;
}

.product-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.product-card p {
  font-size: 16px;
  margin-bottom: 20px;
}

.read-more {
  padding: 10px 20px;
  border: none;
  background: black;
  color: white;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.read-more:hover {
  background: white;
  color: black;
  border: 1px solid black;
}

.product-image-link {
  text-decoration: none;
}

.product-image-link:hover {
  text-decoration: none;
}
.product-filter-options {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.product-filter-options button {
  padding: 10px 20px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.product-filter-options button.active {
  background-color: #007bff;
  color: white;
}

.product-filter-options button:hover {
  background-color: #ddd;
}
