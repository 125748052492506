.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5rem;
}

.error-page__heading-title {
  font-size: 36px;
  font-weight: 500;
  color: var(--bs-primary) !important;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.364);
}

.error-page__heading-description {
  margin-top: 10px;
  font-size: 26px;
  font-weight: 200;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

.eyes {
  display: flex;
  justify-content: center;
  gap: 2px;
}

.eye {
  width: 80px;
  height: 80px;
  background-color: var(--bs-primary) !important;
  border-radius: 50%;
  display: grid;
  place-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
}

.eye__pupil {
  width: 30px;
  height: 30px;
  background-color: var(--bs-white) !important;
  border-radius: 50%;
  animation: movePupil 2s infinite ease-in-out;
  transform-origin: center center;
}

@keyframes movePupil {
  0%,
  100% {
    transform: translate(0, 0);
  }

  25% {
    transform: translate(-10px, -10px);
  }

  50% {
    transform: translate(10px, 10px);
  }

  75% {
    transform: translate(-10px, 10px);
  }
}
