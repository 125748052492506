.blog--detail-desciption {
    font-size: 18px !important;
    font-family: system-ui !important;
}

.blog--detail-content  span {
    font-size: 24px !important;
    color: #3f85c6  !important; 
}

.blog--detail-content  strong {
    font-size: 24px !important;
    color: #3f85c6  !important; 
}

.blog--detail-content  p {
    font-size: 16px !important;
   line-height: 1.3rem !important;
}
.blog--detail-content  b {
    color: inherit !important;
}
