.navbar-light .navbar-nav .nav-link {
  margin-right: 25px;
  padding: 19px 0;
  color: var(--bs-dark) !important;
  font-size: 18px;
  font-weight: 400;
  outline: none;
  transition: color 0.5s;
  position: relative;
  overflow: hidden;
}

.navbar-light .navbar-nav .nav-link::after,
.navbar-light .navbar-nav .nav-link::before {
  content: '';
  position: absolute;
  left: 50%;
  right: 50%;
  height: 4px;
  background-color: var(--bs-text);
  transition: transform 0.8s ease;
  transform: scaleX(0);
  transform-origin: center;
}

.navbar-light .navbar-nav .nav-link::after {
  display: none;
}
.navbar-light .navbar-nav .nav-link::before {
  bottom: 20px;
}

.navbar-light .navbar-nav .nav-link:hover::before,
.navbar-light .navbar-nav .nav-link:hover::after,
.navbar-light .navbar-nav .nav-link.active::before,
.navbar-light .navbar-nav .nav-link.active::after {
  transform: scaleX(1);
  left: 0;
  right: 0;
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
}

.sticky-top.navbar-light .navbar-nav .nav-link {
  padding: 20px 0;
  color: var(--dark) !important;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--bs-text) !important;
}

.navbar-light .navbar-brand img {
  max-height: 50px;
  transition: 0.5s;
}

.sticky-top.navbar-light .navbar-brand img {
  max-height: 45px;
}

.sticky-top.navbar-light .navbar-brand img {
  max-height: 45px;
}

@media (max-width: 992px) {
  nav.bg-transparent {
    position: absolute;
    width: 100%;
    background: transparent !important;
  }

  .navbar.navbar-expand-lg button span {
    position: relative;
    z-index: 99;
  }

  .navbar {
    position: relative;
    background: var(--bs-light);
    z-index: 2;
  }

  .sticky-top.navbar-light {
    position: relative;
    background-color: rgba(233, 233, 238, 1) !important;
    padding: 0 20px 20px 20px !important;
  }

  .sticky-top.navbar-light {
    background: transparent;
  }

  nav.navbar {
    padding: 20px 0 20px 0;
  }

  .navbar.navbar-expand-lg .navbar-toggler {
    padding: 9px 13px;
    border: 2px solid var(--bs-primary);
    color: var(--bs-primary);
  }

  .navbar-light .navbar-collapse {
    margin-top: 15px;
  }

  .navbar-light .navbar-nav .nav-link,
  .sticky-top.navbar-light .navbar-nav .nav-link {
    padding: 10px 0;
    font-size: 16px !important;
    margin-left: 6px;
    margin-right: 10px;
    color: var(--bs-dark) !important;
  }

  .navbar-light .navbar-brand img {
    max-height: 45;
  }
}

@media (min-width: 992px) {
  .navbar-light {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    background: transparent !important;
    z-index: 999;
  }

  .sticky-top.navbar-light {
    position: fixed;
    background: var(--bs-light) !important;
  }

  .navbar-light .navbar-nav .nav-link::before {
    bottom: 13px !important;
  }
}
@media (max-width: 425px) {
  .logo-img img {
    height: auto !important;
    max-width: 130px !important;
  }
}

@media (max-width: 450px) {
  .logo-img img {
    height: auto !important;
    max-width: 130px !important;
  }

  .navbar-light .navbar-nav .nav-link::before {
    display: none !important;
  }

  .navbar.navbar-expand-lg .navbar-toggler {
    padding: 5px 7px 4px 7px;
    border: 2px solid var(--bs-primary);
    color: var(--bs-primary);
    border-radius: 7px;
  }
}

@media (max-width: 992px) {
  .navbar-light .navbar-nav .nav-link::before {
    display: none !important;
  }
}

@media (max-width: 1024px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 16px !important;
    margin-left: 14px;
    margin-right: 1px;
  }

  .navbar-light .navbar-nav .nav-link::before {
    bottom: 0px !important;
  }
}

@media (min-width: 1025px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 17px !important;
    margin-left: 16px;
    margin-right: 0px;
  }
}
@media (min-width: 1100px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 18px !important;
    margin-left: 17px;
  }
}
@media (min-width: 1150px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 18px !important;
    margin-left: 20px;
  }
}

@media (min-width: 1200px) {
  .navbar-light .navbar-nav .nav-link {
    font-size: 18px !important;
    margin: 0 25px 0 0;
  }
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  border-radius: 10px;
  transition: box-shadow 0.15s ease-in-out;
  
}

.collapse {
  transform-origin: top;
}

@media (min-width: 320px) and (max-width: 768px) {
  .collapse {
    animation: collapseAnimation 0.3s ease forwards;
  }

  .collapse.show {
    animation: expandAnimation 0.3s ease forwards;
  }

  @keyframes expandAnimation {
    0% {
      transform: scaleY(0);
      opacity: 0;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }

  @keyframes collapseAnimation {
    0% {
      transform: scaleY(1);
      opacity: 1;
    }
    100% {
      transform: scaleY(0);
      opacity: 1;
    }
  }
}

@media (max-width: 319px), (min-width: 769px) {
  .collapse {
    animation: none; 
  }
}
